<template>
	<div>
		<v-row>
			<v-col cols="12" class="month-col" >
				<v-date-picker  
				no-title ref="picker"
				v-model="date" 
				:picker-date.sync="pickerDate"
				locale="et-et" 
				full-width
				:min="minDate"
				:max="maxDate"
				id="my-month-slider-js-tweak"  
				>
				</v-date-picker>
			</v-col> 
		</v-row>
	</div>   
</template>



<script> 

	import jQuery from 'jquery'

	export default {
		props: ['tabchange'],

		created() { 
			this.setMaxDate()
		},
		mounted() { 
			this.getMonth();

			//RUN EVERY SECOND AND DISABLE CONTEXT MENU ON DATEPICKER + SEE ASLO CSS
			setInterval(function() {
				jQuery('#my-month-slider-js-tweak .v-date-picker-header__value .accent--text button').attr("disabled", true);
			}, 1000);
 
		},

		watch: {  
			pickerDate(){
				this.$emit("event_month", this.pickerDate); 
				this.getMonth();
			},

			tabchange(val){
				
				if(val == 0) {
					//console.log("tab MONTH change is 0", val)
					this.getMonth();
				}  
			}			
		},


		data: () => ({  
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			pickerDate: null, 
			consumption: null,  
			minDate: "2021-12-01",
			maxDate: null

		}),

		methods: {
			setMaxDate(){

        const d = new Date();
        this.maxDate = d.toJSON().slice(0,10);

				/*const d = new Date();
				const currentMonthInt = ((new Date().getMonth()+1)%12 + 1)-2; //NB! -2
				d.setMonth(currentMonthInt);
				this.maxDate = d.toJSON().slice(0,10);*/
			},
			getPayload(){

				let currentDate = new Date();
				let askYear = currentDate.toLocaleDateString("en-US", {
					year: "numeric"
				});
		
				let askMonth = currentDate.toLocaleDateString("en-US", {
					month: "2-digit"
				});
				let askYearMonth = askYear+"-"+askMonth;
				let isCurrentMonth = false;
				if(askYearMonth == this.pickerDate){
					isCurrentMonth = true;
				}
 
				return { 
					periodIsMonth: true, 
					pickerDate: this.pickerDate,
					askYearMonth: askYearMonth,
					isCurrentMonth: isCurrentMonth
				}				
			},


 
			getMonth(){
				
				let url = 'https://andur.maasoojus.ee/backend/endpoint-front-get-month.php';

				
				let payload = this.getPayload(); 
				//console.log("THIS PAYLOAD");
				//console.log(payload);		 
				var self = this;

				jQuery.ajax({   
					url: url,
					type: "POST",
					dataType: 'json', 
					data: {"pickerDate": payload.pickerDate}, //NB sub
					success: function (result) {
						//console.log("OKKK");
						//console.log(result);  
						self.$emit("event_consumed", result);  
						
					},
					error: function (err) {
						//alert("Sõnumit ei saanud edastada");
						console.log("errrrr"); 
						console.log(err); 
					}
				});

			}
		}
	}
</script>   
<style >
	/*HIDE SOME COMPOENNT STUFF, also JQUERY */
	#my-month-slider-js-tweak .v-date-picker-header__value button {text-transform: uppercase; font-size: 23px; color: black!important; cursor: unset; }
	#my-month-slider-js-tweak .v-date-picker-table {display: none!important;}  

</style>