<template>
	<div>
		<v-row>
			<v-col cols="12" class="day-col" >

					<div class="d-flex justify-space-between"> 
						<div></div>
						<div id="datepicker-big"> 
							<v-menu
								ref="menu1"
								v-model="menu1"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
										<v-text-field
										v-model="dateFormatted"
										
										prepend-icon="mdi-calendar"
										v-bind="attrs"
										@blur="date = parseDate(dateFormatted)"
										v-on="on"
										></v-text-field>
								</template>
								<v-date-picker
									v-model="date"
									no-title 
									locale="et-et" 
									:min="minDate"
									:max="maxDate" 									
									@input="menu1 = false"
								></v-date-picker>
							</v-menu>
						</div>
						<div ></div>
					</div>
<!--
<pre>{{$data}}</pre>

<p>Date in ISO format: <strong>{{ date }}</strong></p>
-->



			</v-col> 
		</v-row>
	</div>   
</template>



<script> 

	import jQuery from 'jquery'

	export default {
		props: ['tabchange'],

		data: vm => ({
				date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
				menu1: false,
				minDate: null, //will set
				maxDate: null //will set
			}),

			mounted(){

				this.setMaxDate();
        this.minDate = "2020-12-01";
        //console.log("mindate", this.minDate)
				//this.getDay();
			},
			computed: {
				computedDateFormatted () {
					return this.formatDate(this.date)
				},
			},

			watch: {
				date () { //(val)  
					this.dateFormatted = this.formatDate(this.date);
					this.getDay();

				},

				tabchange(val){
					
					if(val == 1) {
						//console.log("tab DAY change is 1", val)
						this.getDay();
					}  
				}
			},
			

			methods: {
				formatDate (date) {
					if (!date) return null

					const [year, month, day] = date.split('-');
					return `${day}-${month}-${year}`
					//return `${month}/${day}/${year}`
				},

				parseDate (date) {
					if (!date) return null

					const [day, month, year] = date.split('-')  //date.split('/')
					return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
				},

				setMaxDate(){
					const d = new Date();
					this.maxDate = d.toJSON().slice(0,10);

				},

				getDay(){
					
					let url = 'https://andur.maasoojus.ee/backend/endpoint-front-get-day.php';

					var self = this;

					jQuery.ajax({   
						url: url,
						type: "POST",
						dataType: 'json', 
						data: {"pickerDate": this.date}, //NB sub
						success: function (result) {
							//console.log("OKKK");
							//console.log(result);  
							self.$emit("event_consumed", result);  
							
						},
						error: function (err) {
							//alert("Sõnumit ei saanud edastada");
							console.log("errrrr"); 
							console.log(err); 
						}
					});

				}				
			},
	}

</script>   

<style >
 #datepicker-big {width:160px;  } 
 #datepicker-big .v-text-field {font-size: 24px; font-weight: bold; padding-top: 4px;} 

</style>