<template>
	<v-app>
 
		<v-container id="app-controller"  class="pa-0 mt-8">
			<v-row no-gutters>
				<v-col id="house-bg" cols="12" sm="12" md="7" lg="8" :style="{ backgroundImage: 'url(' + require('@/assets/maasoojuse-ou-kontor.jpg') + ')' }">  
					<div class="d-flex flex-column justify-space-between  align-start align-content-end " style="height:100%;">
						<div> </div>
						<div >
							<v-container id="year-summary"  >
								<v-row class="pl-1">
									<v-col>
										<div class="flex flex-row justify-space-between  ">
												<img :src="lightningIcon" alt="fire" class="mr-1" id="ico-lightning">
												<span class="house-info"> 440m2 maja küttekulu kokku </span>
										</div>
									</v-col>
								</v-row>
								<v-row class="mt-0">
									<v-col>
										<table>
											<thead>
												<th>Kütteperiood</th>
												<th>Raha </th>
												<th>Energia</th>
												<th>Elektri hind</th>
											</thead>
											<tbody> 
												<tr>
													<td>{{lastYear}} Eelmine aasta</td>
													<td>{{periodLastYearEur}}€</td>
													<td>{{periodLastYearkW}}kW</td>
													<td>{{kWPrice}} senti&sol;kW&sol;h</td>
												</tr>
												<tr>
													<td>{{currentYear}} Jooksev aasta</td>
													<td>{{periodCurrentYearEur}}€</td>
													<td>{{periodCurrentYearkW}}kW</td>
													<td>{{kWPrice}} senti&sol;kW&sol;h</td>
												</tr>

 
											</tbody>
										</table>
									</v-col>
								</v-row>
							</v-container>
						</div>
					</div>
				</v-col> 
				<v-col 				 cols="12" sm="12" md="5" lg="4"   
					style="width: 420px;" 
					id="right-controller" 
					class="bg-very-light-grey" >

					<div  class="pa-6">
							
							



							<v-tabs v-model="tab"  centered class="mb-2" id="period-tab">
								<v-tab>Kuus</v-tab>
								<v-tab>Päevas</v-tab>  				
							</v-tabs>

							<v-tabs-items v-model="tab">
								<v-tab-item>  
									<MonthSlider :tabchange="tab" v-on:event_consumed="setPumpConsumedEnergy" v-on:event_month="setMonth" />
								</v-tab-item>
								<v-tab-item> 
									<DaySlider :tabchange="tab" v-on:event_consumed="setPumpConsumedEnergy"  />
								</v-tab-item>
							</v-tabs-items>




						<ElectricHeat :kwhConsumed="consumedEnergy" :kwhPrice="kWPrice"
							:kwhMultiplierForElectricHeat="kWhMultiplierForElectricHeat" />

						<v-row fill-height class="mt-2">
							<v-col>
								<Temperature icon="sun" where="Õues" info="Tallinn, Estonia"
									:temperature="monthAvarageTemperature" />
							</v-col>
							<v-col>
								<Temperature icon="fire" where="Toas" info="Ruumid 440m2" temperature="22" />
							</v-col>
						</v-row>

						<v-row class="mt-2">
							<v-col>
								<div id="price-configurator">
									<v-row no-gutters>
										<v-col>
											<div id="info">
												<span class="text-black">Elektri hind</span>
											</div>
										</v-col>
										<v-col>
											<div id="insert">
												<div>
													<v-text-field label="Senti/kW/H" outlined v-model.number="kWPrice"
														type="number" dense class="ma-0 pa-0">
													</v-text-field>
												</div>
											</div>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</v-row>	
					</div>						
				</v-col>

			</v-row>
 
		</v-container> 

		<!-- <pre>{{$data}}</pre>-->

	</v-app>
</template>

<script>


	import jQuery from 'jquery';

	import MonthSlider from './components/MonthSlider';
	import ElectricHeat from './components/ElectricHeat';
	import Temperature from './components/Temperature';
	import DaySlider from './components/DaySlider';

	import Lightning from "./assets/Lightning.svg";

	
	//ALLIKAS https://www.ilmateenistus.ee/kliima/kuukokkuvotted/

	export default {
		name: 'App',

		components: {
			MonthSlider,
			ElectricHeat,
			Temperature,
			DaySlider
		},

		methods: {
			setPumpConsumedEnergy: function (payload) { 
				this.consumedEnergy = payload;
			},
			setMonth: function (payload) {
				this.month = payload.substr(-2, payload.indexOf('-'));
			},
			/*setDay: function () { 
				this.day = new Date().toISOString().slice(0, 10);  //TEMPORARY
			},*/
			setAvgTemp: function () {
				this.monthlyAvarages.forEach(element => {
					let key = Object.keys(element)[0];
					if (this.month == key) {
						this.monthAvarageTemperature = element[key];
					}
				});
			},


			getPeriodRangeKW(xxx, yyy, prop){  //SAME AS getMonth  

				let url = 'https://andur.maasoojus.ee/backend/endpoint-front-get-range.php'; 

 
				var self = this;

				jQuery.ajax({   
					url: url,
					type: "POST",
					dataType: 'json', 
					data: {"fromDate": xxx, "toDate": yyy}, //NB sub
					success: function (result) { 
						self[prop] = result; 
						return result
					},
					error: function (err) {  
						console.log(err); 
					}
				});

			},

		
			setLastYearSummary(){
				this.getPeriodRangeKW(this.lastYear+'-01-01',  this.lastYear+'-12-31', 'periodLastYearkW'); //NB! self keyword setter 
			},

			setCurrentYearSummary(){ 
				let currentDate = new Date().toJSON().slice(0, 10);
				this.getPeriodRangeKW(this.currentYear+'-01-01',  currentDate, 'periodCurrentYearkW'); //NB! self keyword setter
			},
		


		},

		mounted() {
			//alert("Andmed alates 15 veebruar kuni eile. (Vahepealseid andmeid ei ole. Selle peaks tuletama)");
			//this.setDay();

			this.lastYear = new Date().getFullYear() - 1;
			this.currentYear = new Date().getFullYear(); //const currentYear = new Date().toISOString().slice(0, 4);

 
			this.setLastYearSummary(); 
			this.setCurrentYearSummary(); 

 

		},

		watch: {
			month: function () {
				this.setAvgTemp();
			},
			period2021kW: function () {
				this.period2021eur = parseInt((this.kWPrice * this.period2021kW) / 100) ; 
			}, 
			periodLastYearkW: function () {
				this.periodLastYearEur = parseInt((this.kWPrice * this.periodLastYearkW) / 100) ; 
			},  
			periodCurrentYearkW: function () {
				this.periodCurrentYearEur = parseInt((this.kWPrice * this.periodCurrentYearkW) / 100) ; 
			}, 
			kWPrice: function () {
				this.periodLastYearEur = parseInt((this.kWPrice * this.periodLastYearEur) / 100); 
				this.periodCurrentYearEur = parseInt((this.kWPrice * this.periodCurrentYearEur) / 100);
			},
		},

		data: () => ({

			lightningIcon: Lightning,

			lastYear: null,
			currentYear: null,


			kWPrice: 15,     //user can change
			consumedEnergy: 0, //compoent info 
			kWhMultiplierForElectricHeat: 5.1, 
			tab: 0,
			month: null,
			monthAvarageTemperature: null,
			monthlyAvarages: [
				{ "01": -3.1 },
				{ "02": -3.8 },
				{ "03": -0.6 },
				{ "04": 4.9 },
				{ "05": 10.5 },
				{ "06": 14.8 },
				{ "07": 17.8 },
				{ "08": 16.7 },
				{ "09": 12.2 },
				{ "10": 6.7 },
				{ "11": 2.1 },
				{ "12": -1 }
			],

			//calculate on load and on price change
			periodLastYearkW: 0,
			periodLastYearEur: 0,
			periodCurrentYearkW: 0,
			periodCurrentYearEur: 0,
		}),
	};
</script>
<style>
	/* https://fonts.google.com/specimen/Josefin+Sans?query=josefin+sans  */
	@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;1,200&display=swap');
	#house-bg {  
		background-size: cover;  
	}


 
	.text-gray {color: #ccc;}
	#year-summary th {text-align: left; padding-left: 8px; padding-right: 8px;}
	#year-summary td {text-align: left; padding-left: 8px; padding-right: 8px;}



	#ico-fire, #ico-lightning, #ico-sun { vertical-align: sub;}
	#ico-fire {color: red;}
	#ico-lightning {color: blue;}
	#ico-sun {color: yellow;}

	#app-controller { max-width: 1290px;}
	#right-controller { width: 422px;}

	#period-tab  .v-tab {border: 1px solid black; height: 2.5rem;}
	#period-tab  .v-tab:last-child {border-left: 0px;}

	#period-tab  .v-tab--active {background-color: black; color: white;}
	#period-tab  .v-tabs-slider-wrapper {display: none;}




	.day-col,
	.month-col  { height: 85px;}
    


	/*COLORING */ 
	.bg-very-light-grey {background-color: #E5E5E5;}
	.bg-light-grey {background-color: #D6D4D9;}
	.bg-medium-grey {background-color: #86828C;}

	.bg-orange {background-color: #FF7B00;}
	.bg-green {background-color: #00917E;}
	.bg-dark {background-color: #352F40;}
	.bg-white {background-color: white;}

	#el-heat-wrap {background-color: white;}
 

	#right-controller {font-family: 'Josefin Sans';}
	#right-controller,
	#right-controller .v-tabs-bar__content,
	#right-controller .v-tabs-items,
	#right-controller .v-picker.v-card .v-date-picker-header { background-color: #E5E5E5; }
	#right-controller .v-tab {
		font-family: 'Josefin Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 15px; 
		text-align: center;
		text-transform: uppercase; 
		color: #EFEFEF;
		background-color: white; 
		
		height: 30px; 
		padding-top: 2px;
		padding-left: 2rem;
		padding-right: 2rem;
		color: #352F40;
		border-radius: 2px;
		
	}
	#right-controller  .v-tab--active {
		background-color: #352F40; 
		color: #EFEFEF;
		border: 1px solid #352F40; 
		border-radius: 2px;
	}

	#right-controller .v-picker .v-date-picker-header__value .accent--text button { text-transform: capitalize; }

	#right-controller .v-btn--icon.v-size--default .v-icon, 
	#right-controller .v-btn--fab.v-size--default .v-icon {
		height: 32px;
		font-size: 32px;
		width: 32px;
		color: #352f40;
	}

	#right-controller .highlight-ground {
		background-color: #00917E;
		margin-left: -28px; 
		margin-right: -28px; 
		padding-left: 16px; 
		padding-right: 16px; 
	}
	.highlight-electric {
		background-color: #00917E;
		margin-left: -28px;
		margin-right: -28px;
		padding-left: 12px;
		padding-right: 12px;
		margin-bottom: -16px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	.highlight-ground span {color: white; }
	.highlight-electric span {color: white; }


	#el-heat-wrap,
	#temperature-wrap,
	#price-configurator,
	#app-controller {
		background-color: white; 
		border: 1px solid #D6D4D9;
		border-radius: 4px;
	}
	#app-controller > .row { background-color: #e5e5e5;}

	
	#year-summary {
		background-color: white;  
		border-top-right-radius: 4px;
		border-bottom-right-radius: 0px;
		border-right: 1px solid #D6D4D9;
		border-top: 1px solid #D6D4D9;
		/*margin-left:-1px;
		margin-bottom:-1px;*/
	}


 
	#costs {
		font-family: 'Josefin Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;   
		color: #86828C;    
	}
 
	#area span,
	#area span.m2 {
		font-family: 'Josefin Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 14px; 
		/*color: #352F40; */
		color: #86828C;    
	}

	#comparison {border-top: 1px solid transparent;}

	#comparison  .v-expansion-panel-header {padding-left: 28px;}
	#comparison  .v-expansion-panel-content__wrap {padding: 0 28px 16px;}

	#temperature-wrap  .where {
		font-size: 12px; 
		color: #86828C;
		text-transform: uppercase;		
	}
 

	#temperature-wrap  .city {
		font-size: 16px;
		line-height: 16px; 
		color: #352F40;		
	}
	#temperature-wrap  .temperature {
		color: black; font-size: 2rem;
	}

	#temperature-wrap  .when {
		line-height: 12px; 
		color: #86828C;		
	}

 
	#price-configurator #info {padding: 24px 8px 8px 12px;}
	#price-configurator #insert {padding: 14px 8px 8px 16px;}
	#price-configurator #insert input {padding-top: 12px; }
	#price-configurator #insert .v-text-field__details  {display: none;}


	#year-summary .house-info {
		font-size: 14px; 
		color: #86828C;
	}

	#year-summary th {
		font-family: 'Josefin Sans';
		font-size: 12px;
		line-height: 12px; 
		color: #86828C;
		font-weight: 400;
	}
	#year-summary td {
		padding-top: 14px;
		font-family: 'Josefin Sans';
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;  
		color: #000000; 
	}

	#datepicker-big  .mdi-calendar, 
	#datepicker-big .v-text-field > .v-input__control > .v-input__slot:after { color: #352F40!important; }


	@media only screen and (max-width: 959px){
		#house-bg {height: 600px;}
	}


</style>