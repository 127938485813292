import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import _ from 'lodash'


import jQuery from "jquery";
window.jQuery = jQuery

//import $ from "jquery"; //babel
//var $ = require( "jquery" );  //Browserify/Webpack
/*
import jQuery from "jquery";
window.jQuery = jQuery
*/



Vue.config.productionTip = true

new Vue({
  vuetify,
  jQuery,
  render: h => h(App)
}).$mount('#app')
